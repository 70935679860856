$dark: #222;
$gray: #3d3d3d;
$soc_size: 2.5vw;

body{
  background-color: $dark;
}

.backgroundApp{
    background: $dark;
    color: #fff;
    transition: .7s;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 0;
}
.App{
	font-family: SourceCodePro-Regular;
    font-size: 20px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    transition: .7s;
}
.Logo__Container{
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    align-items: center;
    .Helper{
        font-family: SourceCodePro-Regular;
        position: absolute;
        top: -5.5vh;
        width: 100px;
        height: 1.5em;
        text-align: center;
        border-radius: .3em;
        background-color: $gray;
        opacity: .5;
        p{
            margin: .2em auto;
        }
        &:after{
            content: '';
            width: 0;
            height: 0;
            display: flex;
            margin: 0 auto;
            border-style: solid;
            border-width: .7em .7em 0 .7em;
            border-color: #3d3d3d transparent transparent transparent;
            line-height: 0px;
            _border-color: #3d3d3d #000000 #000000 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
        }
    }
    .Logo{
        user-select: none;
        -ms-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        display: flex;
        img{
            height: 5vw;
            margin: 0 .7vw;
            pointer-events: none;
        }
        .Period{
            height: 2vw;
            align-self: flex-end;
        }
    }
}
.Soc{
    display: flex;
    justify-content: center;
    a{
        width: $soc_size;
        height: $soc_size;
        margin: 2vw 1vw;
        cursor: pointer;
    }
    .vk{
        background: url('./img/vk.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
    .tg{
        background: url('./img/telegram.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
    .git{
        background: url('./img/github.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}
.Marquee_top {
	font-family: Montserrat-Black;
    width: 110%;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    left: -1em;
    top: 1em;
    transition: 1s;
    p{
        -webkit-text-stroke: .1vw #fff;
        color: #00000000;
        display: inline-block;
        animation: marquee 5s linear infinite;
        width: 65vw;
        font-size: 10vw;
        text-transform: uppercase;
    }
    #vk_marquee{
        animation: marquee 2s linear infinite;
        width: 20vw;
    }
    #git_marquee{
        animation: marquee 4s linear infinite;
        width: 45vw;
    }
}
.Marquee_bottom {
	font-family: Montserrat-Black;
    width: 110%;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    left: -1em;
    bottom: 1em;
    transition: 1s;
    p{
        -webkit-text-stroke: .1vw #fff;
        color: #00000000;
        display: inline-block;
        animation: marquee_reverse 5s linear infinite;
        width: 35vw;
        width: 65vw;
        font-size: 10vw;
        text-transform: uppercase;
    }
    #vk_marquee{
        animation: marquee_reverse 2s linear infinite;
        width: 20vw;
    }
    #git_marquee{
        animation: marquee_reverse 4s linear infinite;
        width: 45vw;
    }
}
.Preloader{
    width: 100vw;
    height: 100vh;
    background-color: $dark;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: 1s;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
@keyframes marquee{
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}
@keyframes marquee_reverse{
    0%   { transform: translate(-100%, 0); }
    100% { transform: translate(0, 0); }
  }
@media (max-width:800px) {
    .Logo__Container{
        width: 90vw;
        .Helper{
            display: none;
        }
        .Logo{
            height: 8vw;
            img{
                height: 8vw;
            }
            .Period{
                height: 1.3vh;
            }
        }
    }
    .Soc{
        margin-top: .7em;
        a{
            margin: .5em 2.5vw;
            width: 8vw;
            height: 8vw;
        }
    }
}